'use client'

import { useState } from 'react'
import Image from 'next/image'
import { Menu } from 'lucide-react'
import Link from 'next/link'

import Logo from '../../public/Logo.png'

function Header () {
  const [menuOpen, setMenuOpen] = useState(false)

  return (
    <>
      <div className='h-28 items-center px-36 bg-[#1a145b] justify-between lg:flex hidden'>
        <Link href='/'>
          <Image className='w-[133px] h-[75px]' src={Logo} alt='Mamalluca Logo' priority placeholder='empty' />
        </Link>
        <div className='text-white'>
          <ul className='flex flex-row gap-10 text-md'>
            <li className='cursor-pointer py-3 border-b-4 border-b-white'>
              <Link href='/'>Reservas</Link>
            </li>
            <Link href='/panel'>
              <li className='cursor-pointer py-3 font-normal tracking-normal hover:border-b-4 border-b-white'>
                Panel
              </li>
            </Link>
          </ul>
        </div>
      </div>
      <div className='flex justify-center items-center py-2 px-5 bg-[#1a145b] lg:hidden'>
        <div className='flex-grow flex justify-center'>
          <Image src='/Logo.png' alt='' width={205} height={60} />
        </div>
        <button onClick={() => setMenuOpen(!menuOpen)} className='ml-auto text-white'>
          <Menu size={40} />
        </button>
      </div>

      {menuOpen && (
        <div className=' text-white text-baseline'>
          <ul className='flex flex-col gap-4 pl-5 py-5 bg-[#1a145b]'>
            <li className='cursor-pointer'><Link href='/'>Reservas</Link></li>
            <li className='cursor-pointer'><Link href='/panel'>Panel</Link></li>
          </ul>
        </div>
      )}
    </>
  )
}

export default Header
