'use client'

import { useState } from 'react'

function ReadMore ({ description }) {
  const [readMore, setReadMore] = useState(false)
  return (
    <>
      {readMore ? description : `${description.substring(0, 125)}...`}

      <button className='bg-transparent border-transparent capitalize text-blue-400 text-base cursor-pointer pl-1' onClick={() => setReadMore(!readMore)}>
        {readMore ? 'ver menos' : '  ver más'}
      </button>
    </>

  )
}

export default ReadMore
